import DePayWidgets from '@depay/widgets'
import Dialog from "../components/Dialog"
import DisableAutocompletesEffect from "../helpers/DisableAutocompletesEffect.js"
import LoadingText from "../components/LoadingText"
import moment from 'moment'
import React, { useState, useRef, useEffect } from "react"
import { GET, POST } from '../helpers/Queries'
import { Store } from '../Store'

export default function(props) {

  const [ signup, setSignup ] = useState(false)
  const [ showEmailDialog, setShowEmailDialog ] = useState(false)
  const [ confirmEmail, setConfirmEmail ] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ creatingCode, setCreatingCode ] = useState(false)
  const [ code, setCode ] = useState('')
  const [ showRetry, setShowRetry ] = useState(false)
  const [ retryIn, setRetryIn ] = useState()
  const [ loginFailed, setLoginFailed ] = useState(false)
  const [ signupInstead, setSignupInstead ] = useState(false)
  const [ loginType, setLoginType ] = useState()
  const [ externalId, setExternalId ] = useState()
  const [ message, setMessage ] = useState()
  const [ signature, setSignature ] = useState()
  const [ show2FA, setShow2FA ] = useState(false)
  const [ otp, setOtp ] = useState()
  const [ error, setError ] = useState()
  const [ submitting2FA, setSubmitting2FA ] = useState(false)
  const emailInput = useRef()
  const codeInput = useRef()
  const emailForm = useRef()
  const codeForm = useRef()

  const postLogin = (responseData) =>{
    GET("/api/accounts").then((accounts)=>{
      if(accounts && accounts.length) {
        let activeAccount = accounts.find((account)=> account.id === responseData.user.active_account_id )
        if(!activeAccount) { activeAccount = accounts[0] }
        Store.dispatch(({ type: 'account/select', account: activeAccount }))
      }
      Store.dispatch(({ type: 'user/set', user: responseData.user }))
      Store.dispatch(({ type: 'refreshToken/set', token: responseData.refresh_token }))
      Store.dispatch(({ type: 'csrfToken/set', token: responseData.csrf_token }))
      if(props.afterLogin && typeof props.afterLogin == 'function') {
        setTimeout(()=>{ props.afterLogin() }, 300)
      }
    })
  }

  const connect = (event)=>{
    let signingAccount
    DePayWidgets.Login({ message: (account)=>{
      signingAccount = account
      let dateTime = moment().format()
      return `Click to log in to DePay and to accept DePay's Terms of Service: https://depay.com/legal/terms\n\n${dateTime}\n${account}`
    }, recover: ({ message, signature, wallet })=> {
      setLoginType('wallet')
      setExternalId(signingAccount)
      setMessage(message)
      setSignature(signature)
      setOtp()
      setError()
      let endpoint = wallet.name === 'World App' ? `/api/login?safe=worldchain` : `/api/login`
      return fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({ external_id: signingAccount, message, signature, wallet_name: wallet?.name, wallet_type: wallet?.name }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content
        }
      }).then(async(response)=>{
        if(response) {
          if(response.status == 200) {
            postLogin(await response.json())
          } else if (response.status === 400) {
            response.json().then((error)=>{
              if(error.code === 'OTP_INVALID') {
                setShow2FA(true)
              }
            })
          } else if(response.status == 422) { // InvalidAuthenticity token (reload)
            window.location.reload(true)
          }
        }
      })
    }})
  }

  const loginWalletWith2FA = ()=>{
    if(submitting2FA) { return }
    setSubmitting2FA(true)
    setError()

    if(loginType === 'wallet') {
      return fetch(`/api/login`, {
        method: 'POST',
        body: JSON.stringify({ external_id: externalId, message, signature, otp }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content
        }
      }).then(async(response)=>{
        setSubmitting2FA(false)
        if(response) {
          if(response.status == 200) {
            postLogin(await response.json())
          } else if (response.status === 400) {
            response.json().then((error)=>{
              if(error.code === 'OTP_INVALID') {
                setError('Code is invalid!')
              }
            })
          } else if(response.status == 422) { // InvalidAuthenticity token (reload)
            window.location.reload(true)
          } else {
            response.text().then(setError)
          }
        }
      })
    }
  }

  const loginWith2FA = ()=>{
    if(loginType === 'wallet') {
      loginWalletWith2FA()
    } else if(loginType === 'email') {
      onSubmitCode()        
    }
  }

  useEffect(()=>{
    if(otp && otp.length === 6) {
      loginWith2FA()
    }
  }, [otp])

  const onSubmitContinue = (type)=>{
    setCreatingCode(true)
    if(type == undefined){ type = signup ? 'signup' : 'login' }
    fetch(`/api/login/code`, {
      method: 'POST',
      body: JSON.stringify({ email, type }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content
      }
    }).then(async(response)=>{
      if(response.status == 201) {
        setConfirmEmail(true)
        setTimeout(()=>{ if(codeInput.current) { codeInput.current.focus() } }, 200)
      } else if(response.status == 403) {
        response.json().then((data)=>{
          if(data.code == 'EMAIL_DOES_NOT_EXIST_YET') {
            setSignupInstead(true)
          } else if(data.code == 'RETRY_IN') {
            setRetryIn(data.value)
            setShowRetry(true)
            setConfirmEmail(false)
          } 
        })
      } else if(response.status == 422) { // InvalidAuthenticity token (reload)
        window.location.reload(true)
      }
    })
  }

  const onSubmitCode = ()=>{
    fetch(`/api/login`, {
      method: 'POST',
      body: JSON.stringify({ email, code, otp }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.querySelector('[name=csrf-token]').content
      }
    }).then(async(response)=>{
      if(response.status == 200) {
        postLogin(await response.json())
      } else if (response.status === 400) {
        response.json().then((error)=>{
          if(error.code === 'OTP_INVALID') {
            if(show2FA) {
              setError('Code is invalid!')
            }
            setLoginType('email')
            setShow2FA(true)
          }
        })
      } else if(response.status == 422) { // InvalidAuthenticity token (reload)
        window.location.reload(true)
      } else {
        setError('Login failed!')
        setLoginFailed(true)
      }
    })
  }

  const onClickEmail = ()=>{
    setShowEmailDialog(true)
    setTimeout(()=>{ if(emailInput.current) { emailInput.current.focus() } }, 400)
  }

  useEffect(()=>{
    if(showRetry) {
      let interval = setInterval(()=>{
        if(retryIn <= 0) {
          setShowRetry(false)
          setCreatingCode(false)
          clearInterval(interval)
        }
        setRetryIn(retryIn-1)
      }, 1000)
      return ()=>{ clearInterval(interval) }
    }
  }, [retryIn, showRetry])

  useEffect(DisableAutocompletesEffect, [])

  return(

    <div>

      <Dialog
        open={ show2FA }
        close={ ()=>{
          if(submitting2FA) { return false }
          setShow2FA(false)
        }}
        header={ 'Additional Authentication (2FA)' }
        body={
          <div className="text-center pb-2">
            <div className="font-size-xl pt-2"><i className="fa-solid opacity-50 fa-user-shield font-size-xxl"></i></div>
            <div className="pt-2 px-2">
              <p className="m-0">Enter the 6-digit code generated by your authenticator app:</p>
            </div>
            <div className="pt-2">
              <form onSubmit={(e)=>{ e.preventDefault(); loginWith2FA(); return false; }}>
                <input autoFocus={true} disabled={submitting2FA} value={otp || ''} onChange={(event)=>setOtp(event.target.value)} placeholder="316512" required className="row bg-white rounded-2 mb-2 p-2 px-2 form-control form-control-lg text-center" type="text"/>
                {
                  error &&
                  <div className="alert alert-danger py-2">
                    { error }
                  </div>
                }
                <div>
                  <button type="submit" className="btn btn-primary btn-lg rounded-2 w-100" disabled={ submitting2FA }>
                    { !submitting2FA && <span>Submit</span> }
                    { submitting2FA && <LoadingText>Submitting</LoadingText> }
                  </button>
                </div>
              </form>
            </div>
          </div>
        }
        noFooter={ true }
      />

      <Dialog
        open={ showEmailDialog }
        close={ ()=>{
          setConfirmEmail(false)
          setEmail('')
          setCode('')
          setShowEmailDialog(false)
          setShow2FA(false)
          setCreatingCode(false)
          setShowRetry(false)
          setLoginFailed(false)
          setSignupInstead(false)
          setError()
          setLoginType()
        }}
        header={ signup ? 'Sign up with E-Mail' : 'Log in with E-Mail' }
        body={
          <div>
            { !confirmEmail &&
              <form onSubmit={ (event)=>{ event.preventDefault(); onSubmitContinue(); return false; } }>
                <input value={ email } onChange={ (event)=>setEmail(event.target.value) } required disabled={ confirmEmail } ref={ emailInput } className="form-control w-100 form-control-lg rounded" type="email" name="email" placeholder='Enter your email address'/>
                { signupInstead && 
                  <div className="pt-3 px-2">
                    <small>
                      <i className="fas fa-warning me-2" aria-hidden="true"></i>
                      This E-Mail has not been registered yet. Please sign up instead:
                    </small>
                  </div>
                }
                { signupInstead &&
                  <button type="button" onClick={ ()=>{ setSignup(true); setSignupInstead(false); onSubmitContinue('signup') } } className="btn btn-primary rounded btn-lg mb-2 w-100 mt-3">
                    Sign up instead
                  </button>
                }
                { showRetry && 
                  <div className="pt-3 px-2">
                    <small>
                      <i className="fas fa-warning me-2" aria-hidden="true"></i>
                      You've tried to many times. Retry in { retryIn } seconds...
                    </small>
                  </div>
                }
                { !confirmEmail && !signupInstead &&
                  <button disabled={ creatingCode } type="submit" className="btn btn-primary rounded btn-lg mb-2 w-100 mt-3">
                    { creatingCode && <LoadingText>Continue</LoadingText> }
                    { !creatingCode && <span>Continue</span> }
                  </button>
                }
              </form>
            }
            { confirmEmail &&
              <form onSubmit={ (event)=>{ event.preventDefault(); onSubmitCode(); return false; } }>
                <label>
                  <div className="pt-1 px-2">
                    <small>
                      <i className="fas fa-envelope me-2 opacity-50" aria-hidden="true" style={{ position: 'relative', top: '1px' }}></i>
                      We have sent you an email that contains a code. Please enter that code:
                    </small>
                  </div>
                  { loginFailed &&
                    <div className="pt-3 pb-2 px-2">
                      <button type="button" onClick={()=>window.location.reload(true)} className="p-2 px-3 btn btn-action" style={{ whiteSpace: 'break-spaces' }}>
                        <small>
                          <i className="fas fa-warning me-2" aria-hidden="true"></i>
                          Login failed. Please double-check the code, or click here to start over again.
                        </small>
                      </button>
                    </div>
                  }
                  <div>
                    <input value={ code } onChange={ (event)=>{ setCode(event.target.value) } } required ref={ codeInput } className="col mt-2 form-control form-control-lg rounded w-100" placeholder='Enter code' type="text" autoComplete="off"/>
                  </div>
                </label>
                { signup &&
                  <label className="d-block p-2 pt-3" role="button">
                    <input required className="form-check-input" type="checkbox" name="accept_terms"/>
                    <small className="ps-2">I accept DePay's <a href="https://depay.com/legal/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a></small>
                  </label>
                }
                <button type="submit" className="btn btn-primary rounded btn-lg mb-2 w-100  mt-3">
                  { signup && <span>Sign up</span> }
                  { !signup && <span>Log in</span> }
                </button>
              </form>
            }
          </div>
        }
        noFooter={ true }
      />

      <div className="light"/>

      <div className="container position-relative">

        <div className="login-screen px-3 d-flex w-100 vh-100 align-content-center">

          <div className="align-self-start align-self-md-center d-inline-block w-100 pb-lg-5">
            <div className="col pt-3">
              <div className="text-center p-3 pt-5">
                <div className="p-3 p-lg-4">
                  <div className="font-size-xxl pb-5 pb-lg-4">
                    <img className="login-logo" src="/logo.svg"/>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-4 m-auto pt-3 pt-lg-2">
                  <button onClick={ connect } className="btn btn-primary rounded btn-lg mb-2 w-100">
                    <i className="fas fa-wallet me-2" aria-hidden="true"></i>
                    { !signup && <span className="ms-1">Log in</span>}
                    { signup && <span className="ms-1">Sign up</span>}
                    <span> with Wallet</span>
                  </button>
                </div>
                <div className="col-12 col-md-8 col-lg-4 m-auto py-2">
                  <div className="d-flex opacity-50">
                    <div className="col-5"><hr/></div>
                    <div className="col-2 text-center"><small>or</small></div>
                    <div className="col-5"><hr/></div>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-4 m-auto">
                  <button onClick={ onClickEmail } className="btn btn-action rounded btn-lg mb-2 w-100">
                    <span className="fs-6">
                      <i className={ "fas fa-envelope" } aria-hidden="true"></i>
                      { !signup && <span className="ms-2">Log in</span>}
                      { signup && <span className="ms-2">Sign up</span>}
                      <span> with E-Mail</span>
                    </span>
                  </button>
                </div>

                <div className="pt-4">
                  <div className="d-flex pt-2">
                    <div className="col-4"></div>
                    <div className="col-4 text-center">
                      { signup && <small><button onClick={ ()=>{ setSignup(false) } } className="btn btn-sm btn-link">Log in</button></small> }
                      { !signup && <small><button onClick={ ()=>{ setSignup(true) } } className="btn btn-sm btn-link">Sign up</button></small> }
                    </div>
                    <div className="col-4"></div>
                  </div>
                </div>

                <div className="form-text font-size-xs pt-5">
                  <div className="text-center pt-3 pt-lg-5 pb-4 position-relative px-2 px-md-0 pl-1">
                    <div className="d-block">
                      <img src="/swiss-flag.svg" className="me-2" style={{ height: '12px', position: 'relative', top: '-1px' }}/>
                      <small className="text-dark-grey opacity-50">
                        Made in Switzerland · The Crypto Valley
                      </small>
                    </div>
                    <div className="d-block opacity-50">
                    <small className="text-grey">
                    © Copyright 2024 DePay AG
                    ·
                    All rights reserved
                    </small>
                    </div>
                    <div className="d-block">
                    <small>
                    <a href="https://depay.com" target="_blank" rel="noopener noreferrer">Homepage</a>
                    <span> · </span>
                    <a href="https://depay.com/privacy" target="_blank" rel="noopener noreferrer">Privacy</a>
                    <span> · </span>
                    <a href="https://depay.com/legal/terms" target="_blank" rel="noopener noreferrer">Terms</a>
                    <span> · </span>
                    <a href="https://depay.com/disclaimer" target="_blank" rel="noopener noreferrer">Disclaimer</a>
                    <span> · </span>
                    <a href="https://depay.com/imprint" target="_blank" rel="noopener noreferrer">Imprint</a>
                    </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
