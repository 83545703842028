import React, { useState } from "react"
import { Store } from '../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const activeSubscription = useSelector(state => state.subscription)

  return(

    <div className="screen plan-screen">

      <div className="col-12 col-md-7 col-xl-6">

        <div className="rounded-2 bg-white d-flex overflow-hidden mb-2 shadow">
          <div className="col-4 col-md-3 p-3" style={{ backgroundColor: '#537599' }}>
            <strong className="text-white">Fee</strong>
          </div>
          <div className="col-8 col-md-9 p-3">
            1.5% (Standard)
          </div>
        </div>

        <div className="rounded-2 bg-white d-flex overflow-hidden mb-2 shadow">
          <div className="col-4 col-md-3 p-3" style={{ backgroundColor: '#265281' }}>
            <strong className="text-white">PRO</strong>
          </div>
          <div className="col-8 col-md-9 p-3">
            { activeSubscription &&
              <span>Active</span>
            }
            { !activeSubscription &&
              <div>
                <span>Inactive. </span>
                <a href="/#pro" onClick={(e)=>{ e.preventDefault(); Store.dispatch(({ type: 'paywall/show' })) }}>Upgrade now</a>  
              </div>
            }
          </div>
        </div>

        <div className="px-2 pt-1">
          <small className="text-muted ps-1">
            <i className="fas fa-info-circle me-2"></i>
            Learn more about <a href="https://depay.com/pricing" rel="noopener nofollow" target="_blank">DePay pricing</a>.
          </small>
        </div>

      </div>

    </div>
  )
}
